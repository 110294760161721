import { CIcon } from '~/icons/types'
export const ciXml: CIcon = {
  width: 463.1,
  height: 512,
  paths: [
    {
      d:
        'M172.2 166.7c-4.1-4.1-10.8-4.1-14.9 0l-39.1 39.1c-4.1 4.1-4.1 10.8 0 14.9l39.1 39.1c2.1 2.1 4.8 3.1 7.4 3.1s5.4-1 7.4-3.1c4.1-4.1 4.1-10.8 0-14.9l-31.7-31.7 31.7-31.7c4.2-4 4.2-10.7.1-14.8zm133.7 0c-4.1-4.1-10.8-4.1-14.9 0s-4.1 10.8 0 14.9l31.7 31.7L291 245c-4.1 4.1-4.1 10.8 0 14.9 2.1 2.1 4.8 3.1 7.4 3.1s5.4-1 7.4-3.1l39.1-39.1c4.1-4.1 4.1-10.8 0-14.9l-39-39.2zm-58.5-.9c-5.5-1.8-11.5 1.1-13.3 6.7l-25 74.9c-1.8 5.5 1.1 11.5 6.7 13.3 1.1.4 2.2.5 3.3.5 4.4 0 8.5-2.8 10-7.2l25-74.9c1.8-5.5-1.2-11.4-6.7-13.3z'
    },
    {
      d:
        'M452.6 299.8h-32.4V110.9c0-.3 0-.7-.1-1 0-.1 0-.3-.1-.4 0-.2-.1-.4-.1-.6 0-.2-.1-.3-.1-.5s-.1-.3-.1-.5-.1-.3-.2-.5-.1-.3-.2-.5c-.1-.1-.1-.3-.2-.4-.1-.2-.2-.3-.2-.5-.1-.1-.2-.3-.2-.4-.1-.2-.2-.3-.3-.5-.1-.1-.2-.3-.3-.4-.1-.1-.2-.3-.3-.4-.2-.2-.4-.5-.6-.7l-.1-.1L316.8 3.1l-.1-.1c-.2-.2-.4-.4-.7-.6-.1-.1-.3-.2-.4-.3-.1-.1-.3-.2-.4-.3-.2-.1-.3-.2-.5-.3-.1-.1-.3-.2-.4-.2-.2-.1-.3-.2-.5-.2-.1-.1-.3-.1-.4-.2-.2-.1-.3-.1-.5-.2s-.3-.1-.5-.2c-.2 0-.3-.1-.5-.1s-.3-.1-.5-.1-.4-.1-.5-.1c-.2 0-.3-.1-.5-.1-.3 0-.7 0-1-.1h-256c-5.8 0-10.5 4.7-10.5 10.5v289.3H10.5c-5.8 0-10.5 4.7-10.5 10.5v141.2c0 5.8 4.7 10.5 10.5 10.5h32.4v39.4c0 5.8 4.7 10.5 10.5 10.5h356.3c5.8 0 10.5-4.7 10.5-10.5V462h32.4c5.8 0 10.5-4.7 10.5-10.5V310.3c0-5.8-4.7-10.5-10.5-10.5zM319.8 36l64.4 64.4h-64.4V36zM64 21.1h234.8v89.8c0 5.8 4.7 10.5 10.5 10.5h89.8v178.3H64V21.1zm335.2 469.8H64V462h335.2v28.9zm42.9-49.9h-421V320.9h421V441z'
    },
    {
      d:
        'M83.2 422.3c2.1 2.1 4.8 3.1 7.4 3.1 2.7 0 5.4-1 7.4-3.1l26.5-26.5 26.5 26.5c2.1 2.1 4.8 3.1 7.4 3.1 2.7 0 5.4-1 7.4-3.1 4.1-4.1 4.1-10.8 0-14.9l-26.5-26.5 26.5-26.5c4.1-4.1 4.1-10.8 0-14.9s-10.8-4.1-14.9 0L124.6 366l-26.5-26.5c-4.1-4.1-10.8-4.1-14.9 0s-4.1 10.8 0 14.9l26.5 26.5-26.5 26.5c-4.1 4.1-4.1 10.8 0 14.9zm123.2 3.1c5.8 0 10.5-4.7 10.5-10.5v-39.3l20.5 24c2 2.3 4.9 3.7 8 3.7 3.1 0 6-1.3 8-3.7l20.5-24v39.3c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5V347c0-4.4-2.7-8.4-6.9-9.9-4.1-1.5-8.8-.3-11.7 3l-31.1 36.4-31.1-36.4c-2.9-3.4-7.5-4.6-11.7-3-4.1 1.5-6.9 5.5-6.9 9.9v67.9c.3 5.8 5 10.5 10.9 10.5zm125.9 0h40.2c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-29.6V347c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v67.9c-.1 5.8 4.6 10.5 10.4 10.5z'
    }
  ],
  name: 'xml',
  type: 'regular'
}
