



































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { PARKING_NS } from '~/store/modules/shared/parking/state'
import { getRouteMetaItem } from '~/utils/router'
import PlotHeader from '~/components/plot/header/PlotHeader.vue'
import PlotFooter from '~/components/plot/footer/PlotFooter.vue'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import HeadService from '~/services/HeadService'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ClientFlashMessage from '~/components/shared/flash/ClientFlashMessage.vue'
import { FLASH_NS } from '~/store/modules/shared/flash/state'
import BackendFlashMessage from '~/components/car/BackendFlashMessage.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import FlashService from '~/services/flash/FlashService'
import { LOGIN_MODAL_NS } from '~/store/modules/shared/login-modal/state'
import AppNotifications from '~/components/shared/configurable/notification-center/AppNotifications.vue'
import AppConfirmationModal from '~/components/shared/configurable/modal/AppConfirmationModal.vue'

export default Vue.extend({
  components: {
    AppNotifications,
    BackendFlashMessage,
    PlotFooter,
    PlotHeader,
    ClientFlashMessage,
    AppConfirmationModal,
    CLoginModal: () => import('~/components/shared/login/CLoginModal.vue')
  },
  provide() {
    return {
      i18n: this.$i18n // Alias
    }
  },
  fetch() {
    if (this.isIframe) {
      this.addBodyClass('iframe')
    }
  },
  data() {
    return {
      availableLocales: this.$dep(LanguageSwitcher).getAvailableLocales()
    }
  },
  computed: {
    ...mapState(LOGIN_MODAL_NS, {
      isLoginModalVisible: state => state.isLoginModalVisible
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      currentRoute: state => state.currentRoute
    }),
    ...mapGetters({
      showParking: 'parking/getShowParking'
    }),
    ...mapGetters(PARKING_NS, {
      showParking: 'getShowParking'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapDeps({
      languageSwitcherService: LanguageSwitcher
    }),
    ...mapState(FLASH_NS, {
      clientFlashMessage: state => state.clientFlashMessage
    }),
    isIframe() {
      return getRouteMetaItem(this.$route, 'isIframe')
    },
    isDealerSite() {
      const dealerSiteService = this.$dep(DealerSiteService)
      return dealerSiteService.routeIsOfDealerSite()
    },
    isNoHeaderRoute() {
      return getRouteMetaItem(this.$route, 'noHeader')
    },
    isNoFooterRoute() {
      return getRouteMetaItem(this.$route, 'noFooter')
    },
    mayShowCreditsAlert() {
      return !this.isIframe
    },
    showFooter() {
      return !this.isIframe && !this.isDealerSite && !this.isNoFooterRoute
    },
    showHeader() {
      return !this.isIframe && !this.isDealerSite && !this.isNoHeaderRoute
    },
    showFlashMessages() {
      return !this.isFrame && !this.isDealerSite
    },
    flashService() {
      return this.$dep(FlashService)
    }
  },
  watch: {
    currentRoute(val) {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales(
        val
      )
    },
    $route() {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales()
    }
  },
  beforeMount() {
    this.flashService.setClientFlashFromCookie()
  },
  mounted() {
    window.history.scrollRestoration = 'auto'
  },
  methods: {
    ...mapActions(PAGE_NS, {
      addBodyClass: 'addBodyClass'
    })
  },
  head() {
    const headService = this.$dep(HeadService)
    return headService.createLayoutHead()
  }
})
