import { CIcon } from '~/icons/types'
export const ciExclamationCircle: CIcon = {
  width: '16',
  height: '16',
  paths: [
    {
      d:
        'M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM8 9.16a1.333 1.333 0 1 0 0 2.666A1.333 1.333 0 0 0 8 9.16ZM6.734 4.366l.215 3.942c.01.184.163.329.347.329h1.408a.348.348 0 0 0 .347-.33l.215-3.941A.348.348 0 0 0 8.919 4H7.08c-.2 0-.358.168-.347.367Z'
    },
    {
      d:
        'M8 9.16a1.333 1.333 0 1 0 0 2.666A1.333 1.333 0 0 0 8 9.16ZM6.734 4.366l.215 3.942c.01.184.163.329.347.329h1.408a.348.348 0 0 0 .347-.33l.215-3.941A.348.348 0 0 0 8.919 4H7.08c-.2 0-.358.168-.347.367Z',
      fill: '#fff'
    }
  ],

  name: 'exclamation-circle',
  type: 'solid'
}
