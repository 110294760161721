







































































































































import Vue from 'vue'
import {
  faClock,
  faEnvelope,
  faPlusSquare,
  faSearch,
  faSignInAlt,
  faTimes,
  faUnlock,
  faUser,
  faUserPlus,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { mapGetters, mapState } from 'vuex'
import PlotUserSubmenu from '~/components/plot/header/PlotUserSubmenu.vue'
import MobileMenuEntry from '~/components/shared/header/MobileMenuEntry.vue'
import AdminSubmenu from '~/components/shared/header/AdminSubmenu.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { ADMINUSER_NS } from '~/store/modules/shared/admin/user/state'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import { ciHeart } from '~/icons/source/regular/heart'
import { useModalToggle } from '~/compositions/modal-toggle.ts'

export default Vue.extend({
  components: {
    CAvatar,
    AdminSubmenu,
    MobileMenuEntry,
    PlotUserSubmenu
  },
  setup() {
    const modalToggleFunc = useModalToggle()
    return { modalToggleFunc }
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    },
    menuItems: {
      type: Array,
      required: true
    },
    offerBtnLink: {
      type: String,
      required: true
    },
    offerBtnText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedCheckbox: '',
      selectedSubmenu: '',
      linkToNavigate: ''
    }
  },
  computed: {
    ...mapState(USER_NS, {
      username: 'name',
      avatar: 'avatar'
    }),
    ...mapGetters(USER_NS, {
      totalCredits: 'totalCredits',
      isAnon: 'isSingleOrAnon',
      isAdmin: 'isAdmin'
    }),
    ...mapState(ADMINUSER_NS, {
      adminExtras: 'adminExtras'
    }),
    headerText() {
      return this.selectedSubmenu || this.$t('menu')
    },
    icons() {
      return {
        times: faTimes,
        plusSquare: faPlusSquare,
        users: faUsers,
        envelope: faEnvelope,
        user: faUser,
        unlock: faUnlock,
        userPlus: faUserPlus,
        signInAlt: faSignInAlt,
        heart: ciHeart,
        clock: faClock,
        search: faSearch
      }
    },
    goBackAsText() {
      return this.$t('go back as admin')
    }
  },
  mounted() {
    this.modalToggleFunc.setModalInSearch(true)
    this.modalToggleFunc.setHash('menu')
    this.modalToggleFunc.modalOpen()
    this.modalToggleFunc.setAfterModalCloseCallback(this.afterModalClose)
    this.modalToggleFunc.setOnModalCloseCallback(this.onModalClose)
  },
  methods: {
    closeMenu() {
      this.modalToggleFunc.modalClose(false)
    },
    menuNavigate(e: Event, link: string) {
      if (e) {
        e.preventDefault()
      }
      this.linkToNavigate = link
      this.modalToggleFunc.closeMenu()
    },
    onModalClose() {
      this.$emit('close-menu')
    },
    afterModalClose() {
      if (this.linkToNavigate) {
        const link = this.linkToNavigate
        this.linkToNavigate = ''

        if (
          this.$router.resolve({ path: link }).route.name !== this.$route.name
        ) {
          this.$router.push({
            path: link
          })
        }
      }
    },
    menuClicked(event, title) {
      this.selectedCheckbox = event.target.value
      this.selectedSubmenu = title
    },
    navBackClicked() {
      this.selectedCheckbox = ''
      this.selectedSubmenu = ''
    }
  }
})
