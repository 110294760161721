






















import { computed, defineComponent, useRouter } from '@nuxtjs/composition-api'
import { INHOUSE_DASHBOARD_VISITED } from '~/models/advertising/types'
import { AccessToggleName } from '~/models/user/access-toggles'
import { HeaderDropdownLink } from '~/models/header/header-dropdown'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import RouteGuardService from '~/services/RouteGuardService'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { useI18n } from '~/compositions/i18n'
import { useCookies } from '~/compositions/useCookies'
import { ciTradesMatch } from '~/icons/source/light/trades-match'
import { ciLayout } from '~/icons/source/regular/layout'
import { ciGrid } from '~/icons/source/regular/grid'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciBell } from '~/icons/source/regular/bell'
import { ciChatBubble } from '~/icons/source/regular/chat-bubble'
import { ciChart } from '~/icons/source/regular/chart'
import { ciCalendarFa } from '~/icons/source/regular/calendar-fa'
import { ciAuditShop } from '~/icons/source/regular/audit-shop'
import { ciAuditRequest } from '~/icons/source/regular/audit-request'
import { ciAudit } from '~/icons/source/regular/audit'
import { ciWorker } from '~/icons/source/regular/worker'
import { ciFinancialPlan } from '~/icons/source/regular/financial-plan'
import { ciParking } from '~/icons/source/regular/parking'
import { ciXml } from '~/icons/source/regular/xml'
import { ciClassifiedTransfer } from '~/icons/source/regular/classified-transfer'
import { ciSaleRequest } from '~/icons/source/regular/sale-request'
import { ciTicket } from '~/icons/source/regular/ticket'

export default defineComponent({
  props: {
    inMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const cookies = useCookies()
    const [legacyUrlService, routeGuardService] = useDeps(
      LegacyUrlService,
      RouteGuardService
    )
    const { state, getters } = useNamespacedStore<UserState>(USER_NS)

    const userHasPermission = getters('hasPermission')
    const isOfTypeDealer = getters('isOfTypeDealer')
    const userHasAccessTo = getters('userHasAccessTo')
    const isGuest = getters('isGuest')
    const isAuditsUser = getters('isAuditsUser')

    const accountUrl = computed(() => legacyUrlService.getAccountUrl())
    const notificationsUrl = computed(() => {
      const routeName = isGuest
        ? '__account_notifications'
        : '__notifications_view'
      return router.resolve({ name: routeName }).href
    })
    const userCanTrade = computed(() => routeGuardService.userCanTrade())

    const rogueClassifiedsCount = computed(() =>
      state.rogueClassifiedsCount > 99 ? '99+' : state.rogueClassifiedsCount
    )

    const unreadNotifications = computed(() =>
      state.unreadNotifications > 99 ? '99+' : state.unreadNotifications
    )
    const unreadMessages = computed(() =>
      state.unreadMessages > 99 ? '99+' : state.unreadMessages
    )
    const unreadParkingNotifications = computed(() =>
      state.unreadParkingNotifications > 99
        ? '99+'
        : state.unreadParkingNotifications
    )
    const unreadTradesNotifications = computed(() =>
      state.unreadTradesNotifications > 99
        ? '99+'
        : state.unreadTradesNotifications
    )

    const userMenu = computed(() => {
      let userMenuArray = [] as HeaderDropdownLink[]

      const rogueClassifieds = {
        title: t('transfer classifieds::header'),
        icon: ciClassifiedTransfer,
        url: router.resolve({
          name: '__account_rogue_classifieds'
        }).href,
        hide: !rogueClassifiedsCount.value,
        label: rogueClassifiedsCount.value
      }
      const controlPanel = {
        title: t('control panel'),
        icon: ciLayout,
        url: accountUrl.value
      }

      const myClassifieds = {
        title: t('my classifieds'),
        icon: ciFolderOpen,
        url: '/classifieds/my/'
      }

      const parking = {
        title: `${t('parked::parking_btn')} / ${t('favorites')}`,
        icon: ciParking,
        url: router.resolve({ name: '__parking' }).href,
        hide: !routeGuardService.userHasAccessToParking(),
        label: unreadParkingNotifications.value
      }

      const notifications = {
        title: t('notifications'),
        icon: ciBell,
        url: notificationsUrl.value,
        hide: !notificationsUrl.value,
        label: unreadNotifications.value
      }

      const messages = {
        title: t('messages'),
        icon: ciChatBubble,
        url: router.resolve({
          name: '__account_messages'
        }).href,
        label: unreadMessages.value,
        hide: !routeGuardService.userCanReceiveMessages()
      }

      const trades = {
        title: t('trades'),
        icon: ciTradesMatch,
        url: router.resolve({ name: '__trades' }).href,
        hide: !userCanTrade.value,
        label: unreadTradesNotifications.value,
        useNuxtLink: true
      }

      const ads = {
        title: t('advertise'),
        icon: ciGrid,
        url: router.resolve({
          name: cookies.get(INHOUSE_DASHBOARD_VISITED)
            ? '__advertising_dashboard'
            : '__advertising'
        }).href,
        hide: !routeGuardService.userCanUseInHouseAds(),
        useNuxtLink: true
      }

      if (isOfTypeDealer) {
        userMenuArray = [
          rogueClassifieds,
          controlPanel,
          myClassifieds,
          parking,
          notifications,
          messages,
          {
            title: t('buy_requests'),
            icon: ciSaleRequest,
            url: router.resolve({
              name: '__account_sale_requests'
            }).href,
            label: t('new::neoupper').toUpperCase(),
            hide: !userHasAccessTo(AccessToggleName.SALE_REQUESTS_BUY)
          },
          ads,
          trades,
          {
            title: t('statistics'),
            icon: ciChart,
            url: userHasAccessTo(AccessToggleName.USER_ANALYTICS)
              ? '/analytics/'
              : '/account/statistics/',
            hide: !userHasPermission('classifieds.stats')
          },
          {
            title: t('tickets'),
            url: router.resolve({
              name: '__account_tickets'
            }).href,
            label: state.hasTickets ? '!' : '',
            icon: ciTicket
          },
          {
            title: 'XML',
            icon: ciXml,
            url: router.resolve({
              name: '__account_xml'
            }).href,
            label: state.xmlIssues ? '!' : ''
          },
          {
            title: t('subscription renewal'),
            icon: ciCalendarFa,
            url: router.resolve({
              name: '__account_subscriptions'
            }).href
          }
        ]
      }

      if (isGuest) {
        userMenuArray = [
          rogueClassifieds,
          controlPanel,
          myClassifieds,
          parking,
          notifications,
          messages,
          {
            title: t('sale_requests'),
            icon: ciSaleRequest,
            url: router.resolve({
              name: '__account_sale_requests'
            }).href,
            label: t('new::neoupper').toUpperCase(),
            hide: !userHasAccessTo(AccessToggleName.SALE_REQUESTS_SELL)
          },
          ads,
          trades
        ]
      }

      if (isAuditsUser) {
        userMenuArray = [
          controlPanel,
          notifications,
          messages,
          {
            title: t('audit shops::katastimata'),
            icon: ciAuditShop,
            url: router.resolve({ name: '__account_audits_shops' }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_SHOPS)
          },
          {
            title: t('audit requests'),
            icon: ciAuditRequest,
            url: router.resolve({
              name: '__account_audits_requests'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDIT_REQUESTS)
          },
          {
            title: t('audits::elegxoi'),
            icon: ciAudit,
            url: router.resolve({
              name: '__account_audits_external_certificates'
            }).href
          },
          {
            title: t('auditors'),
            icon: ciWorker,
            url: router.resolve({
              name: '__account_audits_auditors'
            }).href,
            hide:
              !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDITORS) || true // temporarily disable auditors page
          },
          {
            title: t('plans::pricing'),
            icon: ciFinancialPlan,
            url: router.resolve({
              name: '__account_audits_packages'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDIT_PACKAGES)
          }
        ]
      }

      return userMenuArray
    })

    return {
      accountUrl,
      notificationsUrl,
      userCanTrade,
      userMenu
    }
  }
})
