import { CIcon } from '~/icons/types'
export const ciTradesMatch: CIcon = {
  width: 576,
  height: 512,
  paths: [
    {
      d:
        'M560.4 112.4H53.7l87.4-85.7c6.1-6 6.2-15.9.2-22s-15.9-6.2-22-.2L4.7 116.8c-3 2.9-4.7 6.9-4.7 11.1 0 4.2 1.7 8.2 4.7 11.1l114.7 112.4c3 3 7 4.4 10.9 4.4 4 0 8.1-1.6 11.1-4.7 6-6.1 5.9-16-.2-22l-87.4-85.7h506.8c8.6 0 15.6-7 15.6-15.6s-7.2-15.4-15.8-15.4zM15.6 399.6h506.8L435 485.3c-6.1 6-6.2 15.9-.2 22s15.9 6.2 22 .2l114.7-112.4c3-2.9 4.7-6.9 4.7-11.1 0-4.2-1.7-8.2-4.7-11.1L456.7 260.6c-3-3-7-4.4-10.9-4.4-4 0-8.1 1.6-11.1 4.7-6 6.1-5.9 16 .2 22l87.4 85.7H15.6c-8.6 0-15.6 7-15.6 15.6s7 15.4 15.6 15.4z'
    }
  ],
  name: 'trades-match',
  type: 'light'
}
