

























import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { ciLayout } from '~/icons/source/regular/layout'
import { ciChatBubble } from '~/icons/source/regular/chat-bubble'
import { ciBullhorn } from '~/icons/source/regular/bullhorn'
import { ciUser } from '~/icons/source/regular/user'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciHeart } from '~/icons/source/regular/heart'
import { ciClock } from '~/icons/source/regular/clock'
import { ciSearchLight } from '~/icons/source/light/search-light'
import { ciList } from '~/icons/source/regular/list'
import { AccessToggleName } from '~/models/user/access-toggles'
import { mapDeps } from '~/plugins/dependency-container/utils'
import RouteGuardService from '~/services/RouteGuardService'
import { ciCalendarFa } from '~/icons/source/regular/calendar-fa'
import { ciGraph } from '~/icons/source/regular/graph'
import { ciCredit } from '~/icons/source/regular/credit'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import { formatNumber } from '~/utils/number'

export default Vue.extend({
  components: { CNewBadge },
  props: {
    inMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    forParking: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(USER_NS, {
      unreadMessages: 'unreadMessages'
    }),
    ...mapGetters(USER_NS, {
      totalCredits: 'totalCredits',
      userHasPermission: 'hasPermission',
      isAdmin: 'isAdmin',
      isGuest: 'isGuest',
      isDealer: 'isOfTypeDealer',
      isAnon: 'isAnon',
      isAgent: 'isAgent',
      myClassifiedsCount: 'myClassifiedsCount'
    }),
    ...mapDeps({ routeGuardService: RouteGuardService }),
    icons() {
      return {
        user: ciUser,
        heart: ciHeart,
        clock: ciClock,
        search: ciSearchLight,
        announcements: ciBullhorn,
        classifieds: ciFolderOpen,
        messages: ciChatBubble,
        panel: ciLayout,
        list: ciList,
        subscription: ciCalendarFa,
        statistics: ciGraph,
        credits: ciCredit
      }
    },
    accountUrl() {
      if (this.isDealer || this.isGuest) {
        return '/account/'
      }

      return null
    },
    userMenu() {
      const { icons } = this
      let userMenuArray = []

      if (!this.isAnon && !this.forParking) {
        userMenuArray.push({
          title: this.$t('control panel'),
          cicon: icons.panel,
          url: '/account/',
          useNuxtLink: !this.inMobile
        })

        let formattedMessages = null
        if (this.unreadMessages) {
          formattedMessages =
            this.unreadMessages <= 99 ? this.unreadMessages : '99+'
        }

        userMenuArray.push({
          title: this.$t('my classifieds'),
          cicon: icons.classifieds,
          url: '/classifieds/my/',
          label: this.myClassifiedsCount
            ? formatNumber(this.myClassifiedsCount)
            : null
        })

        if (this.isAgent) {
          userMenuArray.push({
            title: this.$t('statistics'),
            cicon: icons.statistics,
            url: '/account/statistics/'
          })
        }

        userMenuArray.push({
          title: this.$t('messages'),
          cicon: icons.messages,
          url: '/account/messages/',
          extra_classes: 'for-messages',
          label: formattedMessages,
          useNuxtLink: !this.inMobile
        })

        userMenuArray.push({
          title: this.$t('account details'),
          cicon: icons.user,
          url: '/account/settings/details/',
          useNuxtLink: !this.inMobile
        })

        if (this.routeGuardService.userCanSeeSubscriptions()) {
          userMenuArray.push({
            title: this.$t('subscription'),
            cicon: icons.subscription,
            url: '/account/subscriptions/',
            useNuxtLink: !this.inMobile
          })
        }

        if (
          this.routeGuardService.userHasAccessToggle(
            AccessToggleName.PLOT_AGENT_PAYMENTS
          ) ||
          this.routeGuardService.userHasAccessToggle(
            AccessToggleName.GUEST_PAYMENTS
          )
        ) {
          userMenuArray.push({
            title: this.$t('buy credits::promo'),
            cicon: icons.credits,
            url: '/account/credits/',
            useNuxtLink: !this.inMobile
          })
        }

        userMenuArray.push({
          title: this.$t('announcements'),
          cicon: icons.announcements,
          url: '/account/announcements/',
          useNuxtLink: !this.inMobile
        })
      } else {
        // parking entries
        userMenuArray = [
          {
            title: this.$t('favorite classifieds'),
            cicon: icons.heart,
            url: '/account/classifieds/',
            useNuxtLink: !this.inMobile
          },
          {
            title: this.$t('favorite searches'),
            cicon: icons.search,
            url: '/account/classifieds/searches/',
            useNuxtLink: !this.inMobile
          },
          {
            title: this.$t('recent classifieds'),
            cicon: icons.clock,
            url: '/account/classifieds/recent/classifieds/',
            useNuxtLink: !this.inMobile
          },
          {
            title: this.$t('recent searches'),
            cicon: icons.clock,
            url: '/account/classifieds/recent/searches/',
            useNuxtLink: !this.inMobile
          },
          {
            title: this.$t('lists'),
            cicon: icons.list,
            url: '/account/classifieds/lists/',
            useNuxtLink: !this.inMobile
          }
        ]
      }

      if (this.isAnon && this.inMobile) {
        userMenuArray = [
          {
            title: this.$t('recent classifieds'),
            cicon: icons.clock,
            url: '/account/classifieds/recent/',
            useNuxtLink: !this.inMobile
          },
          {
            title: this.$t('recent searches'),
            cicon: icons.search,
            url: '/account/classifieds/recent/searches/',
            useNuxtLink: !this.inMobile
          }
        ]
      }

      // const { icons } = this
      // if (this.isDealer) {
      //   userMenuArray = [
      //     {
      //       title: this.$t('control panel'),
      //       url: this.accountUrl
      //     },
      //     {
      //       title: this.$t('messages'),
      //       url: '/account/messages/inbox/',
      //       extra_classes: 'for-messages',
      //       label: this.unreadMessages
      //     },
      //     {
      //       title: this.$t('statistics'),
      //       url: '/account/statistics/',
      //       hide: !this.userHasPermission('classifieds.stats')
      //     },
      //     {
      //       title: this.$t('outlets'),
      //       icon: icons.building,
      //       url: '/account/dealers/',
      //       hide: !(this.outletsCount && this.outletsCount > 1)
      //     },
      //     {
      //       title: this.$t('invoices'),
      //       icon: icons.file,
      //       url: '/account/invoices/',
      //       label: this.invoicesCount || null
      //     },
      //     {
      //       title: this.$t('buy renewals') + ' - Credits',
      //       icon: icons.shoppingCart,
      //       url: '/account/renew/'
      //     },
      //     {
      //       title: this.$t('subscription renewal'),
      //       icon: icons.addressCard,
      //       url: '/account/subscription/'
      //     }
      //   ]
      // }
      //
      // if (this.isGuest) {
      //   userMenuArray = [
      //     {
      //       title: this.$t('control panel'),
      //       icon: icons.user,
      //       url: this.accountUrl
      //     },
      //     ...notificationsItem,
      //     {
      //       title: this.$t('messages'),
      //       icon: icons.envelope,
      //       url: '/user/messages/',
      //       extra_classes: 'for-messages',
      //       label: this.unreadMessages
      //     },
      //     {
      //       title: this.$t('account details'),
      //       icon: icons.gear,
      //       url: '/user/settings/'
      //     },
      //     {
      //       title: this.$t('transfer classifieds::header'),
      //       icon: icons.exchange,
      //       url: '/user/rogue-classifieds/',
      //       hide: !this.rogueClassifiedsCount,
      //       label: this.rogueClassifiedsCount || null
      //     },
      //     {
      //       title: this.$t('buy renewals') + ' - Credits',
      //       icon: icons.shoppingCart,
      //       url: '/user/credits/purchase/',
      //       hide: !this.userHasPermission('credits.purchase')
      //     }
      //   ]
      // }

      return userMenuArray
    }
  }
})
